import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/news-detail/index.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/special',
    name: 'special',
    component: () => import('../views/special/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/special-list',
    name: 'special-list',
    component: () => import('../views/special-list/index.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
